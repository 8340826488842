import ApiService from '@/services/core/api.service'

const VehicleCategoryService = {
  async get (query) {
    return ApiService.get(`logistics-officer/vehicle-categories?${query}`)
  },

  async post (payload) {
    return ApiService.post('logistics-officer/vehicle-categories', payload)
  },

  async put (payload) {
    return ApiService.put(`logistics-officer/vehicle-categories/${payload.id}`, payload)
  }
}

export default VehicleCategoryService
