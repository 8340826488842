import sharedRoutes from './shared-routes'
import adminRoutes from './admin-routes'
import purchasingRoutes from './purchasing-routes'
import logisticsManagerRouters from './logistics-manager-routes'
import seniorMechanicRoutes from './senior-mechanic-routes'
import logisticsOfficerRoutes from './logistics-officer-routes'
import userRoutes from './user-routes'

const allRoutes = []

export default allRoutes.concat(
  sharedRoutes,
  adminRoutes,
  purchasingRoutes,
  logisticsManagerRouters,
  seniorMechanicRoutes,
  logisticsOfficerRoutes,
  userRoutes
)
