function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/logistics-officer/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const logisticsOfficerRoutes = [
  {
    path: '/logistics-officer/dashboard',
    component: view('Dashboard'),
    name: 'logistics-officer.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/approved-requests',
    component: view('ApprovedRequests'),
    name: 'logistics-officer.approved-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Approved Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/pending-requests',
    component: view('PendingRequests'),
    name: 'logistics-officer.pending-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Approved Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/vehicle-categories',
    component: view('VehicleCategories'),
    name: 'logistics-officer.vehicle-categories',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Vehicle Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/vehicles',
    component: view('Vehicles'),
    name: 'logistics-officer.vehicles',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Vehicles',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/suppliers',
    component: view('Suppliers'),
    name: 'logistics-officer.suppliers',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Suppliers',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/reports/vehicle-request-histories/:status?',
    component: view('VehicleRequestHistories'),
    name: 'logistics-officer.vehicle-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Vehicle Request Histories',
          active: true
        }
      ]
    }
  }
]

export default logisticsOfficerRoutes
