export const types = {
  SAVE_REQUEST: 'SAVE_REQUEST',
  CLEAR_REQUEST: 'CLEAR_REQUEST'
}

export const state = {
  repairRequest: {
    id: 0,
    company: 1,
    organization: null,
    vehicle: null,
    vehicle_name: null,
    vehicle_type: null,
    vehicle_registration_expiry: null,
    damage_items: []
  }
}

export const getters = {
  repairRequest: state => state.repairRequest
}

export const mutations = {
  [types.SAVE_REQUEST] (state, repairRequest) {
    state.repairRequest = repairRequest
  },
  [types.CLEAR_REQUEST] (state) {
    state.repairRequest = null
  }
}

export const actions = {
  saveRepairRequest ({ commit }, repairRequest) {
    commit(types.SAVE_REQUEST, repairRequest)
  },

  clearRepairRequest ({ commit }) {
    commit(types.CLEAR_REQUEST)
  }
}
