import ApiService from '@/services/core/api.service'

const MaintenanceService = {
  async get (query) {
    return ApiService.get(`senior-mechanic/maintenances?${query}`)
  },

  async post (payload) {
    return ApiService.post('senior-mechanic/maintenances', payload)
  },

  async put (payload) {
    return ApiService.put(`senior-mechanic/maintenances/${payload.id}`, payload)
  },

  async getVehicleHistories (query) {
    return ApiService.get(`senior-mechanic/vehicle-histories?${query}`)
  },

  async getLastOdometer (query) {
    return ApiService.get(`senior-mechanic/last-odometer?${query}`)
  }
}

export default MaintenanceService
