function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/logistics-manager/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const logisticsManagerRouters = [
  {
    path: '/logistics-manager/dashboard',
    component: view('Dashboard'),
    name: 'logistics-manager.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-manager/pending-requests',
    component: view('PendingRequests'),
    name: 'logistics-manager.pending-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Pending Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-manager/reports/vehicle-request-histories/:approved_by_status?',
    component: view('VehicleRequestHistories'),
    name: 'logistics-manager.vehicle-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Vehicle Request Histories',
          active: true
        }
      ]
    }
  }
]

export default logisticsManagerRouters
