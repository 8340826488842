import ApiService from '@/services/core/api.service'

const MaintenanceService = {
  async get (query) {
    return ApiService.get(`logistics-officer/completions?${query}`)
  },

  async post (payload) {
    return ApiService.post('logistics-officer/completions', payload)
  },

  async put (payload) {
    return ApiService.put(`logistics-officer/completions/${payload.id}`, payload)
  }
}

export default MaintenanceService
