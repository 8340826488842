function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/senior-mechanic/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const seniorMechanicRoutes = [
  {
    path: '/senior-mechanic/dashboard',
    component: view('Dashboard'),
    name: 'senior-mechanic.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/senior-mechanic/pending-requests',
    component: view('PendingRequests'),
    name: 'senior-mechanic.pending-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Pending Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/senior-mechanic/reports/vehicle-request-histories/:noted_by_status?',
    component: view('VehicleRequestHistories'),
    name: 'senior-mechanic.vehicle-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Vehicle Request Histories',
          active: true
        }
      ]
    }
  }
]

export default seniorMechanicRoutes
