import ApiService from '@/services/core/api.service'

const ListService = {
  async getCompanies () {
    return ApiService.get('shared/list/companies')
  },

  async getOrganizations () {
    return ApiService.get('shared/list/organizations')
  },

  async getPositions () {
    return ApiService.get('shared/list/positions')
  },

  async getVehicleCategories () {
    return ApiService.get('shared/list/vehicle-categories')
  },

  async getVehicles () {
    return ApiService.get('shared/list/vehicles')
  },

  async getSuppliers () {
    return ApiService.get('shared/list/suppliers')
  },

  async getVehiclesWithVehicleCategories (filter = '') {
    return ApiService.get(`shared/list/vehicles/with/vehicle-categories?${filter}`)
  },

  async getVehicleMaintenanceHistories (filter = '') {
    return ApiService.get(`shared/list/vehicles/with/maintenance-histories?${filter}`)
  },

  async getLastOdometer (query) {
    return ApiService.get(`shared/list/vehicles/last-odometer?${query}`)
  }
}

export default ListService
